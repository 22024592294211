import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { RichtextEditor } from "@cncxt/imodium-component-library";
import { useTranslation } from "react-i18next";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import styles from "./results.module.scss";
import { withTrans } from "../../../../i18n/withTrans";

interface PropTypes {
  value: string;
  location: JSON;
}

function SearchResultsPage(props: PropTypes): JSX.Element {
  const { t } = useTranslation();
  const [result, setResult] = useState({ footerData: "", data: [] });
  const [pageNumber, setPageNumber] = useState(1);
  const [pages, setPages] = useState([]);
  // Total number of articles to be shown per page
  const perPageCount = 10;
  // Function to calculate total number of pages for pagination
  const getTotalNumberOfPages = () => {
    return (
      Math.floor(result.data.length / perPageCount) +
      (result.data.length % perPageCount ? 1 : 0)
    );
  };
  // Function to handle when page numbers in pagination are clicked
  const handlePageClick = item => {
    if (item === t("search.first")) setPageNumber(1);
    else if (item === t("search.previous") && pageNumber !== 1)
      setPageNumber(pageNumber - 1);
    else if (item === t("search.last")) {
      setPageNumber(getTotalNumberOfPages());
    } else if (
      item === t("search.next") &&
      pageNumber !== getTotalNumberOfPages()
    )
      setPageNumber(pageNumber + 1);
    else setPageNumber(parseInt(item));
  };
  // useEffect to watch for changes to the search term
  useEffect(() => {
    const getResults = async () => {
      setResult({
        footerData: props.location["state"]["footerData"],
        data: props.location["state"]["searchResults"],
      });
      setPageNumber(1);
    };
    getResults();
  }, [props.value]);
  // useEffect to watch for and carry out some necessary steps on changes to search results or to selected page number from pagination div
  useEffect(() => {
    let tempPages: string[] = [];
    let totalNumberOfPages = -1;
    if (totalNumberOfPages === -1) totalNumberOfPages = getTotalNumberOfPages();
    for (let i = 1; i <= totalNumberOfPages; i++) {
      tempPages.push(i.toString());
    }
    if (getTotalNumberOfPages() !== 1)
      tempPages =
        pageNumber === 1
          ? [...tempPages, t("search.next"), t("search.last")]
          : pageNumber === totalNumberOfPages
          ? [t("search.first"), t("search.previous"), ...tempPages]
          : [
              t("search.first"),
              t("search.previous"),
              ...tempPages,
              t("search.next"),
              t("search.last"),
            ];
    setPages(tempPages);
  }, [result.data, pageNumber]);
  // Component
  return (
    <DefaultLayout siteFooterData={result.footerData}>
      {result.data.length !== 0 ? (
        <div className={styles.pageLayout}>
          <div className={styles.searchHeading}>
            <p>
              {t("search.Results")}{" "}
              <span className={styles.italics}>
                {(pageNumber - 1) * perPageCount + 1}
              </span>{" "}
              -{" "}
              <span className={styles.italics}>
                {pageNumber !== getTotalNumberOfPages()
                  ? pageNumber * perPageCount
                  : result["data"].length}
              </span>{" "}
              {t("search.for")}{" "}
              <span className={styles.italics}>{props.value}</span>
            </p>
          </div>
          {result["data"]
            .slice((pageNumber - 1) * perPageCount, pageNumber * perPageCount)
            .map((item, index) => {
              return (
                <div
                  className={styles.itemDiv}
                  key={`${item["title"] + index}`}
                >
                  <div className={styles.imgDiv}>
                    {" "}
                    {item["image"] && <img src={item["image"]["src"]} />}
                  </div>
                  <div className={styles.textDiv}>
                    <h3>
                      <a
                        href={item["link"]["href"]}
                        target={item["link"]["target"]}
                        rel={item["link"]["rel"]}
                      >
                        {item["title"]}
                      </a>
                    </h3>
                    <div className={styles.summary}>
                      {item["summary"] !== "" ? (
                        <RichtextEditor jsonRichtextEditor={item["summary"]} />
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })}
          <div className={styles.paginationDiv}>
            {pages.map(item => {
              if (item === t("search.first"))
                return (
                  <p
                    onClick={() => handlePageClick(item)}
                    key={item}
                    className={styles.p}
                  >
                    <span className={styles.arrows}>{"‹‹ "} </span>
                    {item}
                  </p>
                );
              if (item === t("search.previous"))
                return (
                  <p
                    onClick={() => handlePageClick(item)}
                    key={item}
                    className={styles.p}
                  >
                    <span className={styles.arrows}>{"‹ "} </span>
                    {item}
                  </p>
                );
              if (item === t("search.next"))
                return (
                  <p
                    onClick={() => handlePageClick(item)}
                    key={item}
                    className={styles.p}
                  >
                    {item}
                    <span className={styles.arrows}>{" ›"} </span>
                  </p>
                );
              if (item === t("search.last"))
                return (
                  <p
                    onClick={() => handlePageClick(item)}
                    key={item}
                    className={styles.p}
                  >
                    {item}
                    <span className={styles.arrows}>{" ››"} </span>
                  </p>
                );
              if (pageNumber === parseInt(item)) {
                const paraStyle = classnames(styles.p, styles.active);
                return (
                  <p
                    onClick={() => handlePageClick(item)}
                    key={item}
                    className={paraStyle}
                  >
                    {item}
                  </p>
                );
              } else
                return (
                  <p
                    onClick={() => handlePageClick(item)}
                    key={item}
                    className={styles.p}
                  >
                    {item}
                  </p>
                );
            })}
          </div>
        </div>
      ) : null}
    </DefaultLayout>
  );
}

export default withTrans(SearchResultsPage);
