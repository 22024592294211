import React from "react";
import { Router } from "@reach/router";
import SearchResultsPage from "./SearchResultsPage";

export default function SearchResult(): JSX.Element {
  return (
    <Router basepath="/search/site">
      <SearchResultsPage path="/:value" />
    </Router>
  );
}
